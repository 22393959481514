.container {
  display: flex;
  align-items: center;
  width: 500px;
  min-height: 58px;
  background: #e9eef5;
  box-shadow: inset 4px 4px 8px rgba(121, 144, 161, 0.16);
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 16px 24px;
}

.text {
  margin-left: 8px;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 22px;
  color: #1887e8;
}

.error {
  color: #f62f36;
}

.warning {
  color: #f8a14a;
}