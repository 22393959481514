.mainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &Loading {
    @extend .mainContainer;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 426px;
  }
}

.header {
  color: #202730;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-top: 8px;
  padding-bottom: 10px;
}

.countrySelect {
 max-height: 180px !important;
}

.inputWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.supportLink {
  padding: 0 !important;
  min-width: unset !important;
  height: unset !important;

  a {
    font-weight: 500 !important;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  max-width: 500px;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 10px;

  button {
    min-width: 100px;
  }
}