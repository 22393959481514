.formInputLabel {
  display: block;
  margin-bottom: 23px;
  width: 100%;
  max-width: 500px;

  .formLabelText {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #202730;
    margin-bottom: 4px;
  }
}

.formPasswordLinkButton {
  padding-right: 7px !important;
  height: fit-content !important;
}

.formPasswordLink {
  all: unset;
  font-weight: 600;
}

.formDividerSeparator {
  border-bottom: 1px solid #d3d5d9;
  width: 36px;
  height: 1px;
  box-shadow: 1px 0 0 #ffffff;
}

.formSignUpLink {
  min-width: fit-content !important;

  span {
    font-weight: 400 !important;
  }
}
